<template>
  <div class="allmsgclass">
    <navigation msg="设置"></navigation>
    <div class="bomenuall">
      <div @click="$router.push('/setup')" class="bomenu">
        <div>账户设置</div>
        <van-icon color="#C0C4CC" size=".32rem" name="arrow" />
      </div>
      <div @click="refuse" class="bomenu">
        <div>退出登录</div>
        <van-icon color="#C0C4CC" size=".32rem" name="arrow" />
      </div>
    </div>
    <van-popup v-model="zhanghushow">
      <div class="quite1">确认退出登录吗？</div>
      <div class="quite2">
        <button @click="qu1meth" id="qu1">确认</button>
        <button @click="qu2meth" id="qu2">取消</button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import eventbus from "../../../util/eventbus"
export default {
  data() {
    return {
        zhanghushow:false
    };
  },
  methods: {
      qu1meth() {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userId");
      localStorage.removeItem("token");
      localStorage.removeItem("loginTime");
      localStorage.removeItem("guide");
      
      this.zhanghushow = false;
      eventbus.$emit("guievent","");
      this.$router.push("/login");
    },
    qu2meth() {
      this.zhanghushow = false;
    },
    refuse() {
      this.zhanghushow = true;
    },
  },
};
</script>
<style scoped>
.allmsgclass >>> .van-popup--center{
    width: 75%;
    border-radius: 0.2rem;
    overflow: hidden;
}
.quite1 {
  font-size: 0.34rem;
  line-height: 0.68rem;
  text-align: center;
  margin-top: 0.62rem;
  margin-bottom: 0.41rem;
}
#qu1,
#qu2 {
  border: none;
  width: 50%;
  font-size: 0.32rem;
  line-height: 0.44rem;
  text-align: center;
  padding: 0.22rem 0;
  margin-bottom: 0;
}
#qu1 {
  background-color: #f65a16;
  color: #ffffff;
}
#qu2 {
  background-color: #dcdcdc;
  color: #000000;
}
.bomenuall {
  background-color: #ffffff;
  padding: 0 0.34rem;
  border-radius: 0.16rem;
  margin: 0.32rem;
}
.bomenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.32rem;
  font-weight: 400;
  color: #303133;
  line-height: 0.48rem;
  padding: 0.24rem 0;
  border-bottom-color: #f6f6f7;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.bomenu > :last-child {
  border-bottom: none;
}
.quite2 button{
  margin: 0;
}
</style>